import React from "react";
export default function SectionTitle({
  title,
  paragraph,
  center,
  titleWidth,
  paraWidth,
  color,
  margin,
}) {
  return (
    <div
      className={`${margin ? margin : "mb-16"} ${
        center ? "mx-auto text-center" : ""
      }`}
    >
      <h2
        className={`font-bold mt-0 mb-3 text-3xl sm:text-4xl md:text-[40px] leading-tight sm:leading-tight md:leading-tight text-${
          color ? color : "black"
        } ${center ? "mx-auto" : ""}`}
        style={{ maxWidth: `${titleWidth}px` }}
      >
        {title}
      </h2>
      <p
        className={`font-medium text-base sm:text-lg leading-relaxed sm:leading-relaxed w-full text-${
          color ? color : "body-color"
        } ${center ? "mx-auto" : ""}`}
        style={{ maxWidth: `${paraWidth}px` }}
      >
        {paragraph}
      </p>
    </div>
  );
}
