import React from "react";
import Faq from "../components/Faq";
import Layout from "../components/layout";
import PricingSection from "../components/PricingSection";
import Seo from "../components/seo";
import Emitter from "../services/emitter";
import { useScript } from '../hooks/useScript'

export default function PricingPage() {
  const [scriptLoaded] = useScript("https://cdn.paddle.com/paddle/paddle.js");

  React.useEffect(() => {
    if (!scriptLoaded) return;

    window.Paddle.Setup({ vendor: 138827 });

    Emitter.on('OPEN_CHECKOUT', (product) => {
      window.Paddle.Checkout.open({
        product: product,
        email: undefined,
        successCallback: () => console.log("purchased"),
        closeCallback: (reason) => console.warn(reason),
      });
    })
  }, [scriptLoaded]);

  return (
    <Layout>
      <Seo title="Pricing" />
      <PricingSection />
      {/* <Faq /> */}
    </Layout>
  );
}
