import React from "react";
import paymentMethods from "./../images/payment-methods.svg";
import PricingPlan from "./PricingPlan";
import PricingTable from "./PricingTable";
import SectionTitle from "./SectionTitle";

const secureIcon = (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.375 9.71599C4.375 7.83286 5.58001 6.16099 7.36651 5.5655L16.1165 2.64883C17.0145 2.34948 17.9855 2.34948 18.8835 2.64883L27.6335 5.5655C29.42 6.16099 30.625 7.83286 30.625 9.71599V17.5002C30.625 25.5239 22.5164 30.3943 19.046 32.1115C18.0651 32.5967 16.9349 32.5967 15.954 32.1115C12.4836 30.3943 4.375 25.5239 4.375 17.5002V9.71599Z"
      fill="url(#paint0_linear_520_230)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9062 13.5521C23.4757 14.1217 23.4757 15.045 22.9062 15.6145L17.8978 20.6229C16.8726 21.6481 15.2107 21.6481 14.1855 20.6229L12.0938 18.5312C11.5243 17.9617 11.5243 17.0383 12.0938 16.4688C12.6633 15.8993 13.5867 15.8993 14.1562 16.4688L16.0417 18.3543L20.8438 13.5521C21.4133 12.9826 22.3367 12.9826 22.9062 13.5521Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_520_230"
        x1="4.81618"
        y1="-12.6012"
        x2="33.918"
        y2="-10.4938"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A356F3" />
        <stop offset="1" stopColor="#6A64F1" />
      </linearGradient>
    </defs>
  </svg>
);

export default function PricingSection() {
  return (
    <section className="bg-[#F5F8FF] pt-[170px] pb-28">
      <div className="container">
        <div className="flex">
          <div className="w-full">
            <SectionTitle
              title="Pricing Plan Comparison"
              paragraph="Simple transparent pricing plans and free to get started."
              paraWidth="690"
              center
            />
          </div>
        </div>

        <PricingPlan />

        <PricingTable />

        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="text-center mb-6">
              <img
                src={paymentMethods}
                alt="paymentMethod"
                className="border border-[#edeef2] rounded-lg overflow-hidden mx-auto"
              />
            </div>
            <p className="max-w-[724px] bg-white border border-[#d8dfff] mx-auto mb-5 text-base text-body-color py-8 px-8 sm:px-10 rounded-md flex">
              <span className="mx-auto pr-3">{secureIcon}</span>
              We do not store any credit card information in server, payments
              are processed by gateways and site is secured by 128 bit SSL
              encryption.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
