import React, { useState } from "react";
import Emitter from "../services/emitter";

const checkmark = (
  <svg
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto w-5 h-5"
  >
    <path
      d="M12.5096 24.5312C5.86325 24.5312 0.507111 19.1406 0.507111 12.5C0.507111 5.85937 5.86325 0.507812 12.5096 0.507812C19.1559 0.507812 24.5511 5.85937 24.5511 12.5C24.5511 19.1406 19.1559 24.5312 12.5096 24.5312ZM12.5096 1.875C6.64517 1.875 1.87547 6.64062 1.87547 12.5C1.87547 18.3594 6.64517 23.1641 12.5096 23.1641C18.374 23.1641 23.1828 18.3594 23.1828 12.5C23.1828 6.64062 18.374 1.875 12.5096 1.875Z"
      fill="#34A853"
    />
    <path
      d="M11.1798 15.2344C10.9061 15.2344 10.6715 15.1562 10.437 14.9609L7.85662 12.4609C7.58295 12.1875 7.58295 11.7578 7.85662 11.4844C8.13029 11.2109 8.56035 11.2109 8.83402 11.4844L11.1798 13.7891L16.1841 8.94531C16.4577 8.67187 16.8878 8.67187 17.1615 8.94531C17.4351 9.21875 17.4351 9.64844 17.1615 9.92188L11.9617 15C11.688 15.1563 11.4144 15.2344 11.1798 15.2344Z"
      fill="#34A853"
    />
  </svg>
);

const crossIcon = (
  <svg
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto w-5 h-5"
  >
    <path
      d="M12.8551 0C5.93859 0 0.364716 5.56911 0.364716 12.4797C0.364716 19.3902 5.93859 25 12.8551 25C19.7716 25 25.3861 19.3902 25.3861 12.4797C25.3861 5.56911 19.7716 0 12.8551 0ZM12.8551 23.5772C6.75229 23.5772 1.7887 18.5772 1.7887 12.4797C1.7887 6.38211 6.75229 1.42276 12.8551 1.42276C18.9579 1.42276 23.9621 6.38211 23.9621 12.4797C23.9621 18.5772 18.9579 23.5772 12.8551 23.5772Z"
      fill="#D34053"
    />
    <path
      d="M16.5988 8.73978C16.314 8.45523 15.8665 8.45523 15.5817 8.73978L12.8558 11.4634L10.1299 8.73978C9.8451 8.45523 9.39756 8.45523 9.11277 8.73978C8.82797 9.02433 8.82797 9.47149 9.11277 9.75604L11.8387 12.4796L9.11277 15.2032C8.82797 15.4878 8.82797 15.9349 9.11277 16.2195C9.23482 16.3414 9.43825 16.4227 9.60099 16.4227C9.76373 16.4227 9.96716 16.3414 10.0892 16.2195L12.8151 13.4959L15.541 16.2195C15.6631 16.3414 15.8665 16.4227 16.0293 16.4227C16.192 16.4227 16.3954 16.3414 16.5175 16.2195C16.8023 15.9349 16.8023 15.4878 16.5175 15.2032L13.8729 12.4796L16.5988 9.75604C16.843 9.47149 16.843 9.02433 16.5988 8.73978Z"
      fill="#D34053"
    />
  </svg>
);

const warningIcon = (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto w-[18px] h-[18px]"
  >
    <path
      d="M10.9821 0C4.90081 0 0 4.90081 0 10.9821C0 17.0634 4.90081 22 10.9821 22C17.0634 22 22 17.0634 22 10.9821C22 4.90081 17.0634 0 10.9821 0ZM10.9821 20.748C5.61626 20.748 1.25203 16.348 1.25203 10.9821C1.25203 5.61626 5.61626 1.25203 10.9821 1.25203C16.348 1.25203 20.748 5.61626 20.748 10.9821C20.748 16.348 16.348 20.748 10.9821 20.748Z"
      fill="#FFA70B"
    ></path>
    <path
      d="M10.8077 14.5312H10.6923C10.3077 14.5312 10 14.8646 10 15.2812C10 15.6979 10.3077 16.0312 10.6923 16.0312H10.8077C11.1923 16.0312 11.5 15.6979 11.5 15.2812C11.5 14.8646 11.1923 14.5312 10.8077 14.5312Z"
      fill="#FFA70B"
    ></path>
    <rect x="10" y="4" width="1.34" height="9" rx="0.67" fill="#FFA70B"></rect>
  </svg>
);

export default function PricingPlan() {
  const [yearly, setYearly] = useState(true);

  const openCheckout = async ({ product }) => {
    Emitter.emit('OPEN_CHECKOUT', product)
  };

  return (
    <>
      <div className="flex items-center justify-center mb-12">
        <span
          className={`font-semibold text-base ${
            yearly
              ? "text-body-color cursor-pointer"
              : "text-primary cursor-default"
          }`}
          onClick={() => setYearly(false)}
        >
          Monthly
        </span>
        <span
          onClick={() => setYearly(!yearly)}
          className="bg-white w-[52px] h-[30px] rounded-full relative shadow-accordion cursor-pointer px-[6px] flex items-center mx-4"
        >
          <span
            className={`w-5 h-5 bg-primary rounded-full transition-all ${
              yearly ? "translate-x-full" : "translate-x-0"
            }`}
          ></span>
        </span>
        <span
          className={`font-semibold text-base relative ${
            yearly
              ? "text-primary cursor-default"
              : "text-body-color cursor-pointer"
          }`}
          onClick={() => setYearly(true)}
        >
          Yearly
          <sup className="absolute -top-3 -right-full bg-primary py-1 px-2 text-white rounded-full text-xs">
            -40%
          </sup>
        </span>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full md:w-1/2 lg:w-1/3 px-4">
          <div className="bg-white rounded-lg shadow-card py-10 xl:px-6 2xl:px-10 px-6 sm:px-8 md:px-6 mb-10 border-2 border-transparent relative">
            <div className="absolute right-6 top-6">
              <svg
                className="w-12 h-12 2xl:w-[60px] 2xl:h-[60px]"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M7.5 32.185V47.5C7.5 51.642 10.8579 55 15 55H45C49.1423 55 52.5 51.642 52.5 47.5V32.185C51.701 32.3905 50.8633 32.5 50 32.5H10C9.13675 32.5 8.29905 32.3905 7.5 32.185Z"
                  fill="#6A64F1"
                />
                <path
                  opacity="0.4"
                  d="M5 20C5 17.2386 7.23858 15 10 15H50C52.7615 15 55 17.2386 55 20V22.5C55 25.2615 52.7615 27.5 50 27.5H10C7.23858 27.5 5 25.2615 5 22.5V20Z"
                  fill="#6A64F1"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30 8.75L27.0278 5.03478C25.746 3.43265 23.8056 2.5 21.7539 2.5H21.25C17.7982 2.5 15 5.29823 15 8.75C15 12.2018 17.7982 15 21.25 15H27.5V55H32.5V15H38.75C42.2017 15 45 12.2018 45 8.75C45 5.29823 42.2017 2.5 38.75 2.5H38.246C36.1943 2.5 34.254 3.43265 32.9722 5.03478L30 8.75ZM24.5969 10L23.1235 8.15825C22.7906 7.7422 22.2867 7.5 21.7539 7.5H21.25C20.5596 7.5 20 8.05965 20 8.75C20 9.44035 20.5596 10 21.25 10H24.5969ZM35.403 10L36.8765 8.15825C37.2092 7.7422 37.7132 7.5 38.246 7.5H38.75C39.4403 7.5 40 8.05965 40 8.75C40 9.44035 39.4403 10 38.75 10H35.403Z"
                  fill="#6A64F1"
                />
              </svg>
            </div>
            <h3 className=" font-semibold text-base md:text-lg 2xl:text-xl text-primary mb-3">
              Free
            </h3>
            <div className="flex items-end mb-5">
              <span className=" font-bold text-2xl md:text-[28px] lg:text-3xl 2xl:text-4xl text-black">
                $0.00
              </span>
              <span className="font-medium text-base md:text-xs lg:text-sm 2xl:text-base text-body-color pl-2"></span>
            </div>
            <h4 className=" font-semibold text-black  pb-4 mb-8 border-b border-stroke">
            Best Suited for Personal, Non-commercial and Hobby Projects
            </h4>

            <div className="space-y-4">
              <Offer text="05 Forms" icon={checkmark} />
              <Offer text="60 Submissions Per Month" icon={checkmark} />
              <Offer text="2 Linked Emails" icon={checkmark} />
              <Offer text="Recaptcha Integration" icon={checkmark} />
              <Offer text="Basic Statistics" icon={warningIcon} />
              <Offer text="Basic Spam Filter" icon={warningIcon} />
              <Offer text="Connect - Email Only" icon={warningIcon} />
              <Offer
                text="Support - Community Support Only"
                icon={warningIcon}
              />
              <Offer text="File Attachment" icon={crossIcon} />
              <Offer text="Custom Redirect After Submission" icon={crossIcon} />
              <Offer text="Auto Responder" icon={crossIcon} />
              <Offer text="Webhook Access" icon={crossIcon} />
              <Offer text="Remove Email Footer Branding" icon={crossIcon} />
              <Offer text="Endpoint Fields Rules" icon={crossIcon} />
            </div>
            <div className="mt-8">
              <a
                href="https://app.formbold.com/register"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="bg-primary btn py-3 px-10 md:px-7 xl:px-8 2xl:px-10 hover:bg-opacity-90"
              >
                Select Plan
              </a>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 px-4">
          <div className="bg-white rounded-lg shadow-card py-10 xl:px-6 2xl:px-10 px-6 sm:px-8 md:px-6 mb-10 border-2 border-[#E1578A] relative">
            <div className="absolute right-6 top-6">
              <svg
                className="w-12 h-12 2xl:w-[60px] 2xl:h-[60px]"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 30H20.4505C21.6422 30 22.6682 30.8412 22.9019 32.0097L25 42.5L13.8867 49.9087C12.2254 51.0165 10 49.8255 10 47.8287V37.5C10 33.3577 13.3579 30 17.5 30Z"
                  fill="#E1578A"
                />
                <path
                  d="M42.5 30H39.5495C38.3577 30 37.3317 30.8412 37.098 32.0097L35 42.5L46.1132 49.9087C47.7747 51.0165 50 49.8255 50 47.8287V37.5C50 33.3577 46.6422 30 42.5 30Z"
                  fill="#E1578A"
                />
                <path
                  d="M32.9143 50H27.0843C25.9001 50 24.8683 50.845 24.9676 52.025C25.1238 53.8835 26.0006 56.4812 29.5098 57.3882C29.8293 57.4707 30.1693 57.4707 30.4888 57.3882C33.9981 56.4812 34.8748 53.8835 35.0311 52.025C35.1303 50.845 34.0983 50 32.9143 50Z"
                  fill="#E1578A"
                />
                <path
                  d="M17.5001 20C19.3747 14.3767 25.4655 8.05059 28.396 5.23684C29.2995 4.36942 30.701 4.36942 31.6042 5.23684C34.535 8.05059 40.6257 14.3766 42.5 20C44.4332 25.7995 42.077 36.217 40.3217 42.431C39.4562 45.495 36.6192 47.5 33.4355 47.5H26.5432C23.3698 47.5 20.5397 45.508 19.6697 42.456C17.9058 36.268 15.5406 25.8777 17.5001 20Z"
                  fill="#F3BCD0"
                />
                <path
                  d="M30 30C32.7614 30 35 27.7614 35 25C35 22.2386 32.7614 20 30 20C27.2386 20 25 22.2386 25 25C25 27.7614 27.2386 30 30 30Z"
                  fill="#E1578A"
                />
              </svg>
            </div>
            <h3 className="font-semibold text-base md:text-lg 2xl:text-xl text-[#E1578A] mb-3">
              Standard
            </h3>
            <div className="flex items-end mb-5">
              <span className="font-bold text-2xl md:text-[28px] lg:text-3xl 2xl:text-4xl text-black">
                <span className="text-xl md:text-xl lg:text-[22px] 2xl:text-[27px] line-through text-body-color pr-[6px]">
                  ${yearly ? "10" : "10"}
                </span>
                ${yearly ? "3" : "5"}
              </span>
              <span className="font-medium text-base md:text-xs lg:text-sm 2xl:text-base text-black">
                /{yearly ? "Mo" : "Mo"}
              </span>
            </div>
            <h4 className="font-semibold text-base  pb-4 mb-8 border-b border-stroke text-black">
            Best Suited for Developers and Freelancers
            </h4>

            <div className="space-y-4">
              <Offer text="100 Forms" icon={checkmark} />
              <Offer text="2000 Submissions Per Month" icon={checkmark} />
              <Offer text="50 Linked Emails" icon={checkmark} />
              <Offer text="Recaptcha Integration" icon={checkmark} />
              <Offer
                text="Detailed Statistics and Data Export"
                icon={checkmark}
              />
              <Offer text="Advanced Spam Filter" icon={checkmark} />
              <Offer
                text="Connect - Email, Slack, and Telegram"
                icon={checkmark}
              />
              <Offer text="Priority Email Support" icon={checkmark} />
              <Offer
                text="File Attachment"
                icon={checkmark}
              />
              <Offer text="Custom Redirect After Submission" icon={checkmark} />
              <Offer text="Auto Responder" icon={checkmark} />
              <Offer text="Webhook Access" icon={checkmark} />
              <Offer text="Remove Email Footer Branding" icon={crossIcon} />
              <Offer text="Endpoint Fields Rules" icon={crossIcon} />
            </div>
            <div className="mt-8">
              <button
                onClick={() => openCheckout({ product: yearly ? 748456 : 754302 })}
                className="bg-[#E1578A] btn py-3 px-10 md:px-7 xl:px-8 2xl:px-10 hover:bg-opacity-90"
              >
                Select Plan
              </button>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 px-4">
          <div className="bg-white rounded-lg shadow-card py-10 xl:px-6 2xl:px-10 px-6 sm:px-8 md:px-6 mb-10 border-2 border-transparent relative">
            <div className="absolute right-6 top-6">
              <svg
                className="w-12 h-12 2xl:w-[60px] 2xl:h-[60px]"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35 20H42.5C46.6423 20 50 23.3579 50 27.5V55H35V20Z"
                  fill="#39A388"
                />
                <path
                  d="M10 18.3558C10 14.9143 12.3422 11.9145 15.681 11.0798L30.681 7.32977C35.4145 6.14637 40 9.72657 40 14.6058V55H10V18.3558Z"
                  fill="#B0DACF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 42.5C20 41.1192 21.1193 40 22.5 40H27.5C28.8807 40 30 41.1192 30 42.5C30 43.8807 28.8807 45 27.5 45H22.5C21.1193 45 20 43.8807 20 42.5Z"
                  fill="#39A388"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 32.5C20 31.1193 21.1193 30 22.5 30H27.5C28.8807 30 30 31.1193 30 32.5C30 33.8807 28.8807 35 27.5 35H22.5C21.1193 35 20 33.8807 20 32.5Z"
                  fill="#39A388"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 22.5C20 21.1193 21.1193 20 22.5 20H27.5C28.8807 20 30 21.1193 30 22.5C30 23.8807 28.8807 25 27.5 25H22.5C21.1193 25 20 23.8807 20 22.5Z"
                  fill="#39A388"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5 52.5C5 51.1193 6.1193 50 7.5 50H52.5C53.8808 50 55 51.1193 55 52.5C55 53.8808 53.8808 55 52.5 55H7.5C6.1193 55 5 53.8808 5 52.5Z"
                  fill="#B0DACF"
                />
              </svg>
            </div>
            <h3 className="font-semibold text-base md:text-lg 2xl:text-xl text-[#39A388] mb-3">
              Business
            </h3>
            <div className="flex items-end mb-5">
              <span className="font-bold text-2xl md:text-[28px] lg:text-3xl 2xl:text-4xl text-black">
                <span className="text-xl md:text-xl lg:text-[22px] 2xl:text-[27px] line-through text-body-color pr-[6px]">
                  ${yearly ? "69" : "69"}
                </span>
                ${yearly ? "19" : "29"}
              </span>
              <span className="font-medium text-base md:text-xs lg:text-sm 2xl:text-base text-black">
                /{yearly ? "Mo" : "Mo"}
              </span>
            </div>
            <h4 className="font-semibold text-base  pb-4 mb-8 border-b border-stroke text-black">
            Best Suited for Team, Agencies and Businessess
            </h4>

            <div className="space-y-4">
              <Offer text="Unlimited Forms" icon={checkmark} />
              <Offer text="10000 Submissions Per Month" icon={checkmark} />
              <Offer text="Unlimited Linked Emails" icon={checkmark} />
              <Offer text="Recaptcha Integration" icon={checkmark} />
              <Offer
                text="Detailed Statistics and Data Export"
                icon={checkmark}
              />
              <Offer text="Advanced Spam Filter" icon={checkmark} />
              <Offer
                text="Connect - Email, Slack, and Telegram"
                icon={checkmark}
              />
              <Offer text="Priority Email Support" icon={checkmark} />
              <Offer
                text="File Attachment"
                icon={checkmark}
              />
              <Offer text="Custom Redirect After Submission" icon={checkmark} />
              <Offer text="Auto Responder" icon={checkmark} />
              <Offer text="Webhook Access" icon={checkmark} />
              <Offer text="Remove Email Footer Branding" icon={checkmark} />
              <Offer text="Endpoint Fields Rules" icon={checkmark} />
            </div>
            <div className="mt-8">
              <button
                onClick={() => openCheckout({ product: yearly ? 748457 : 754303 })}
                className="bg-[#39A388] btn py-3 px-10 md:px-7 xl:px-8 2xl:px-10"
              >
                Select Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Offer = ({ text, icon }) => {
  return (
    <p className="flex items-center font-medium md:text-sm 2xl:text-base text-body-color">
      <span className="pr-2">{icon}</span>
      {text}
    </p>
  );
};
