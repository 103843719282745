import React, { useState } from "react";
import Emitter from "../services/emitter";

const checkmark = (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto"
  >
    <path
      d="M12.5096 24.5312C5.86325 24.5312 0.507111 19.1406 0.507111 12.5C0.507111 5.85937 5.86325 0.507812 12.5096 0.507812C19.1559 0.507812 24.5511 5.85937 24.5511 12.5C24.5511 19.1406 19.1559 24.5312 12.5096 24.5312ZM12.5096 1.875C6.64517 1.875 1.87547 6.64062 1.87547 12.5C1.87547 18.3594 6.64517 23.1641 12.5096 23.1641C18.374 23.1641 23.1828 18.3594 23.1828 12.5C23.1828 6.64062 18.374 1.875 12.5096 1.875Z"
      fill="#34A853"
    />
    <path
      d="M11.1798 15.2344C10.9061 15.2344 10.6715 15.1562 10.437 14.9609L7.85662 12.4609C7.58295 12.1875 7.58295 11.7578 7.85662 11.4844C8.13029 11.2109 8.56035 11.2109 8.83402 11.4844L11.1798 13.7891L16.1841 8.94531C16.4577 8.67187 16.8878 8.67187 17.1615 8.94531C17.4351 9.21875 17.4351 9.64844 17.1615 9.92188L11.9617 15C11.688 15.1563 11.4144 15.2344 11.1798 15.2344Z"
      fill="#34A853"
    />
  </svg>
);

const crossIcon = (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto"
  >
    <path
      d="M12.8551 0C5.93859 0 0.364716 5.56911 0.364716 12.4797C0.364716 19.3902 5.93859 25 12.8551 25C19.7716 25 25.3861 19.3902 25.3861 12.4797C25.3861 5.56911 19.7716 0 12.8551 0ZM12.8551 23.5772C6.75229 23.5772 1.7887 18.5772 1.7887 12.4797C1.7887 6.38211 6.75229 1.42276 12.8551 1.42276C18.9579 1.42276 23.9621 6.38211 23.9621 12.4797C23.9621 18.5772 18.9579 23.5772 12.8551 23.5772Z"
      fill="#D34053"
    />
    <path
      d="M16.5988 8.73978C16.314 8.45523 15.8665 8.45523 15.5817 8.73978L12.8558 11.4634L10.1299 8.73978C9.8451 8.45523 9.39756 8.45523 9.11277 8.73978C8.82797 9.02433 8.82797 9.47149 9.11277 9.75604L11.8387 12.4796L9.11277 15.2032C8.82797 15.4878 8.82797 15.9349 9.11277 16.2195C9.23482 16.3414 9.43825 16.4227 9.60099 16.4227C9.76373 16.4227 9.96716 16.3414 10.0892 16.2195L12.8151 13.4959L15.541 16.2195C15.6631 16.3414 15.8665 16.4227 16.0293 16.4227C16.192 16.4227 16.3954 16.3414 16.5175 16.2195C16.8023 15.9349 16.8023 15.4878 16.5175 15.2032L13.8729 12.4796L16.5988 9.75604C16.843 9.47149 16.843 9.02433 16.5988 8.73978Z"
      fill="#D34053"
    />
  </svg>
);

const warningIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mx-auto"
  >
    <path
      d="M10.9821 0C4.90081 0 0 4.90081 0 10.9821C0 17.0634 4.90081 22 10.9821 22C17.0634 22 22 17.0634 22 10.9821C22 4.90081 17.0634 0 10.9821 0ZM10.9821 20.748C5.61626 20.748 1.25203 16.348 1.25203 10.9821C1.25203 5.61626 5.61626 1.25203 10.9821 1.25203C16.348 1.25203 20.748 5.61626 20.748 10.9821C20.748 16.348 16.348 20.748 10.9821 20.748Z"
      fill="#FFA70B"
    ></path>
    <path
      d="M10.8077 14.5312H10.6923C10.3077 14.5312 10 14.8646 10 15.2812C10 15.6979 10.3077 16.0312 10.6923 16.0312H10.8077C11.1923 16.0312 11.5 15.6979 11.5 15.2812C11.5 14.8646 11.1923 14.5312 10.8077 14.5312Z"
      fill="#FFA70B"
    ></path>
    <rect x="10" y="4" width="1.34" height="9" rx="0.67" fill="#FFA70B"></rect>
  </svg>
);

export default function PricingTable() {
  const openCheckout = async ({ product }) => {
    Emitter.emit('OPEN_CHECKOUT', product)
  };

  const [openTable, setOpenTable] = useState(false);

  return (
    <>
      <div className="pt-5 pb-[70px] text-center">
        <button
          className="inline-flex items-center justify-center font-semibold text-black text-lg sm:text-xl"
          onClick={() => setOpenTable(!openTable)}
        >
          View Detailed comparison
          <span className={`${openTable && "rotate-180"}`}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.645 10.0098L14 15.3648L19.355 10.0098L21 11.6664L14 18.6664L7 11.6664L8.645 10.0098Z"
                fill="black"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        className={`bg-white rounded-lg shadow-card overflow-hidden mb-16 ${
          openTable ? "block" : "hidden"
        }`}
      >
        <div className="w-full overflow-x-auto">
          <table className="table-auto w-full p-[1px]">
            <thead>
              <tr>
                <th
                  className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 border-r border-stroke"
                  aria-label="empty th"
                ></th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left border-r border-stroke">
                  <h3 className="font-bold text-xl text-black mb-4">Free</h3>
                  <div className="flex items-end mb-2">
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $0.00
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      (Free Forever)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for personal and hobby projects.
                  </p>
                  <a
                    href="https://app.formbold.com/register"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    className="bg-primary btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </a>
                </th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left border-r border-stroke">
                  <h3 className="font-bold text-xl text-black mb-4">
                    Standard
                  </h3>
                  <div className="flex items-end mb-2">
                    <span className="font-extrabold text-xl xl:text-2xl text-body-color line-through">
                      $10
                    </span>
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $3
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      /mo (Billed Yearly)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for developers and freelance projects.
                  </p>
                  <button
                    onClick={() => openCheckout({ product: 748456 })}
                    className="bg-black btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </button>
                </th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left">
                  <h3 className="font-bold text-xl text-black mb-4">
                    Business
                  </h3>
                  <div className="flex items-end mb-2">
                    <span className="font-extrabold text-xl xl:text-2xl text-body-color line-through">
                      $85
                    </span>
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $19
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      /mo (Billed Yearly)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for team, agencies and businessess.
                  </p>
                  <button
                    onClick={() => openCheckout({ product: 748457 })}
                    className="bg-black btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="bg-[#FAFBFD]">
                <td className="border border-stroke font-bold text-lg text-black py-5 px-5 xl:px-9">
                  Core Features
                </td>
                <td className="border border-stroke py-5 px-5 xl:px-9"></td>
                <td className="border border-stroke py-5 px-5 xl:px-9"></td>
                <td className="border border-stroke py-5 px-5 xl:px-9"></td>
              </tr>

              <PricingRow
                feature="Forms"
                freePlan="05"
                standardPlan="100"
                businessPlan="Unlimited"
              />

              <PricingRow
                feature="Submissions Per Month"
                freePlan="60"
                standardPlan="2000"
                businessPlan="10000"
              />

              <PricingRow
                feature="Linked Emails"
                freePlan="02"
                standardPlan="50"
                businessPlan="Unlimited"
              />

              <PricingRow
                feature="Recaptcha Integration"
                freePlan={checkmark}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Detailed Statistics and Data Export"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Advanced Spam Filter"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="File Attachment"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Private Emails"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Connect - Email, Slack and Telegram"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Priority Email Support"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Custom Redirect After Submission"
                freePlan={crossIcon}
                standardPlan={checkmark}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Auto Responder"
                freePlan={crossIcon}
                standardPlan={warningIcon}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Webhook Access"
                freePlan={crossIcon}
                standardPlan={warningIcon}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Remove Email Footer Branding"
                freePlan={crossIcon}
                standardPlan={crossIcon}
                businessPlan={checkmark}
              />

              <PricingRow
                feature="Endpoint Fields Rules"
                freePlan={crossIcon}
                standardPlan={crossIcon}
                businessPlan={checkmark}
              />

              <tr>
                <th
                  className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 border-r border-stroke"
                  aria-label="empty th"
                ></th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left border-r border-stroke">
                  <h3 className="font-bold text-xl text-black mb-4">Free</h3>
                  <div className="flex items-end mb-2">
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $0.00
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      (Free Forever)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for personal and hobby projects.
                  </p>
                  <a
                    href="https://app.formbold.com/register"
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    className="bg-primary btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </a>
                </th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left border-r border-stroke">
                  <h3 className="font-bold text-xl text-black mb-4">
                    Standard
                  </h3>
                  <div className="flex items-end mb-2">
                    <span className="font-extrabold text-xl xl:text-2xl text-body-color line-through">
                      $10
                    </span>
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $3
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      /mo (Billed Yearly)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for developers and freelance projects.
                  </p>
                  <button
                    onClick={() => openCheckout({ product: 748456 })}
                    className="bg-black btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </button>
                </th>
                <th className="min-w-[220px] mx-auto px-5 xl:px-9 pb-12 pt-16 text-left">
                  <h3 className="font-bold text-xl text-black mb-4">
                    Business
                  </h3>
                  <div className="flex items-end mb-2">
                    <span className="font-extrabold text-xl xl:text-2xl text-body-color line-through">
                      $85
                    </span>
                    <span className="px-2 font-extrabold text-3xl xl:text-4xl text-black">
                      $19
                    </span>
                    <span className="font-medium text-sm xl:text-base text-body-color">
                      /mo (Billed Yearly)
                    </span>
                  </div>
                  <p className="font-medium text-sm xl:text-base text-body-color mb-5">
                    Best suited for team, agencies and businessess.
                  </p>
                  <button
                    onClick={() => openCheckout({ product: 748457 })}
                    className="bg-black btn hover:bg-opacity-90 flex items-center justify-center w-full h-11"
                  >
                    Select
                  </button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const PricingRow = ({ feature, freePlan, standardPlan, businessPlan }) => {
  return (
    <tr>
      <td className="border border-stroke font-semibold text-base text-body-color py-5 px-5 xl:px-9">
        {feature}
      </td>
      <td className="border border-stroke font-semibold text-base text-body-color py-5 px-5 xl:px-9 text-center">
        {freePlan}
      </td>
      <td className="border border-stroke font-semibold text-base text-body-color py-5 px-5 xl:px-9 text-center">
        {standardPlan}
      </td>
      <td className="border border-stroke font-semibold text-base text-body-color py-5 px-5 xl:px-9 text-center">
        {businessPlan}
      </td>
    </tr>
  );
};
