import React from "react";
import SectionTitle from "./SectionTitle";

export default function Faq() {
  return (
    <section className="bg-white pb-14 md:pb-20">
      <div className="container">
        <div className="flex">
          <div className="w-full">
            <SectionTitle
              title="Frequently Asked Questions"
              paragraph="Answered all frequently asked questions, Still confused?
              feel free to open a support ticket."
              paraWidth="570"
              center
              margin="mb-12"
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4">
            <FaqItem
              question="Can I use Formbold with commercial projects?"
              answer="Yes, you can use with commercial/clients projects without giving us any credits."
            />
            <FaqItem
              question="How many commercial project or domains are allowed?"
              answer="You can use TailGrids for unlimited commercial projects or domains. But you are not allowed to build website/page builder or redistribute Ayro UI source files."
            />
            <FaqItem
              question="Which license type is suitable for me?"
              answer="Everything explained on pricing tables of each plan, if you are still confused ask us via chat or support ticket. We will get back to you"
            />
            <FaqItem
              question="How I can get future updates?"
              answer="As long as you have an active membership with us. You will recieve free updates, new components and download files will be automatically available under your account."
            />
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <FaqItem
              question="What is the meaning of seat?"
              answer="Seat means how many developers/people gonna use TailGrids to develop web page for end-product, but it doesn't means end-users. There is no limit for end-users or end-products."
            />
            <FaqItem
              question="Does Formbold comes with design source files?"
              answer="Yes. We do provide Figma design source files with full UI design."
            />
            <FaqItem
              question="Do you provide support?"
              answer="Yes. Support is provided to all our customers. Please open a support ticket by describing your issue, we will get back to you within 24hrs."
            />
            <FaqItem
              question="Is Formbold well-documented?"
              answer="Indeed. we created entirely separate portal for docs you can access from: ayroui.com/docs if you find anything that is not available on documentation page. Please, feel free to submit a ticket we will get back to you with a solution."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

const FaqItem = ({ question, answer }) => {
  return (
    <div className="mb-11 sm:px-8 md:px-10">
      <h3 className="font-semibold text-xl sm:text-2xl lg:text-xl xl:text-2xl text-black mb-5">
        {question}
      </h3>
      <p className="font-medium text-base sm:text-lg lg:text-base xl:text-lg leading-relaxed sm:leading-relaxed lg:leading-relaxed xl:leading-relaxed text-body-color">
        {answer}
      </p>
    </div>
  );
};
